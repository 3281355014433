<template>
<div class=" flex w-full no-gutter justify-center" id="page-login">
    <a-row style="margin-top:140px" v-bind:class="[ _mobile  ? 'mobile' : 'pc' ]">

        <a-col :span="span" :offset="offSet" style="margin-bottom:21px;text-align:center">
            <div class="logintitle" v-if="except">로그인이 필요한 <br/>서비스입니다.</div>
            <img style="width:113px" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Flogo-blue%402x.png?alt=media&token=9c574e13-0fa9-46fb-8c40-bd2fbbf349d1" />
        </a-col>
        <a-col class="m-div" :span="span" :offset="offSet">
            <div class="l-input" style="border-bottom:1px solid #dbdbdb">
                <a-input placeholder="이메일" v-model="email">
                    <a-icon slot="prefix" type="mail" />
                </a-input>
            </div>
            <div class="l-input" style="margin-top:0px">
                <a-input placeholder="비밀번호" v-model="password" type="password" @pressEnter="login()">
                    <a-icon slot="prefix" type="lock" />
                </a-input>
            </div>
        </a-col>
        <a-col :span="span" :offset="offSet" style="padding-top:15px;padding-bottom:15px;cursor:pointer">
            <div @click="visible=true">
                <a-icon slot="prefix" type="unlock" />&nbsp;패스워드 초기화
            </div>
        </a-col>
        <a-col :span="span" :offset="offSet">
            <div class="s-standard-button-primary">
                <a-button @click="login()">
                    로그인
                </a-button>
            </div>
        </a-col>
        <a-col :span="span" :offset="offSet">
            <a-divider></a-divider>
        </a-col>
        <a-col :span="span" :offset="offSet">
            <div class="s-standard-button">
                <a-button @click="$router.push('/pages/register').catch(() => {})">
                    회원가입
                </a-button>
            </div>
        </a-col>
    </a-row>

    <a-modal v-model="visible" title="페스워드 초기화">
        <template slot="footer">
            <a-button key="back" @click="visible=false">
                취소
            </a-button>
            <a-button key="submit" :disabled="!resetemail" type="primary" @click="onResetPassword()">
                전송하기
            </a-button>
        </template>
        <h5>초기화할 이메일을 입력해주세요</h5>

        <div class="r-input">
            <a-input placeholder="이메일" v-model="resetemail">
                <a-icon slot="prefix" type="mail" />
            </a-input>
        </div>

    </a-modal>
</div>
</template>

<script>
import { isMobile, showSuccess, showError } from "../../components/fnc.js";
import firebase from "firebase";
import auth from "@/auth/authService";
const emailValidation = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;

String.prototype.trims = function() {
  return this.replace(/^\s+|\s+$/g, "");
};

export default {
  data() {
    return {
      email: "",
      visible: false,
      except: false,
      resetemail: "",
      password: "",
      span: 6,
      offSet: 9
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email !== "" && this.password !== "";
    },
    _mobile: function() {
      var _mobile = this.$vssWidth < 1024 ? true : isMobile();
      this.span = _mobile ? 24 : 8;
      this.offSet = _mobile ? 0 : 8;
      return _mobile;
    }
  },
  mounted() {
    if (this.$route.query.except) {
      this.except = true;
    }
  },
  methods: {
    onResetPassword() {
      var self = this;
      if (emailValidation.test(this.resetemail)) {
        firebase
          .auth()
          .sendPasswordResetEmail(self.resetemail.trims())
          .then(
            function() {
              // Email sent.
              self.visible = false;

              showSuccess({
                notify: self.$vs.notify,
                msg:
                  "성공적으로 보냈습니다. [" +
                  self.resetemail +
                  "] 메일을 확인해주세요"
              });
            },
            function(error) {
              showError({
                notify: self.$vs.notify,
                msg: error.message
              });
            }
          );
      } else {
        showError({
          notify: this.$vs.notify,
          msg: "잘못된 이메일 형식 입니다."
        });
      }
    },
    onLogOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          auth.logOut();
          location.reload();
        });
    },
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: "로그인시도",
          text: "이미 로그인이 되어 있습니다.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning"
        });

        return false;
      }
      return true;
    },
    login() {
      // Loading
      if (this.email != "" && this.password != "") {
        this.$vs.loading();

        const payload = {
          userDetails: {
            email: this.email.trims(),
            password: this.password
          },
          notify: this.$vs.notify,
          closeAnimation: this.$vs.loading.close
        };
        this.$store.dispatch("auth/loginAttempt", payload);
      } else {
        this.$vs.notify({
          title: "필수입력 누락",
          text: "모든 값을 채워주세요",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      }
    },
    registerUser() {
      if (!this.checkLogin()) return;
      this.$router.push("/pages/register").catch(() => {});
    }
  }
};
</script>

<style>
.l-input input {
  height: 59px;
  border-left: 0px solid white;
  border-right: 0px solid white;
  border-top: 0px solid white;
  border-bottom: 0px solid white;
  border-radius: 0%;
  outline: none !important;
  -webkit-appearance: none;
}

.r-input input {
  height: 59px;
  border-radius: 0%;
  outline: none !important;
  -webkit-appearance: none;
}

.l-input i {
  color: #0264fb;
}

.l-input input:focus,
.l-input input:hover {
  border-left: 0px solid white !important;
  border-right: 0px solid white !important;
  border-top: 0px solid white !important;
  border-bottom: 0px solid white !important;
  outline-color: transparent !important;
  outline-style: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.m-div {
  padding: 5px;
  border-radius: 5px;
  border: solid 1px #dbdbdb;
}

.logintitle {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  text-align: center;
  color: #0a367b;
  margin-bottom: 60px;
}
#page-login .mobile {
  width: 90%;
  margin: 0 auto;
}
#page-login .pc {
  width: 100%;
  margin: 0 auto;
}
</style>
